import React from "react"
import { Container, Row, Col } from "react-bootstrap"
import { StaticImage } from "gatsby-plugin-image"
import styled from "styled-components"
import Layout from "../../components/Layout"
import Seo from "../../components/Seo"
import { Link } from "gatsby"
import { BiArrowBack } from "react-icons/bi"
const Actions = () => {
  return (
    <Layout>
      <Seo
        title="Actions for Split Testing ASINs Advertising Strategies"
        description="Split Test for ASIN to see which strategy works best"
      />
      <Container fluid className="px-0">
        <div className="text-center " style={{ position: "relative" }}>
          <Styledh1 className="display-4">Actions and Split Testing</Styledh1>
          <StaticImage
            className="mt-2"
            src="../../assets/images/featuresbg.jpg"
            alt="Amazon FBA App Features"
            placeholder="tracedSVG"
            layout="fullWidth"
            aspectRatio={6 / 1}
            style={{ zIndex: "-1" }}
          ></StaticImage>
        </div>
        <Container className="p-5">
          <Row className="mx-auto">
            <Col>
              <Row className="text-center">
                <Col sm={12} className="pb-5">
                  <StaticImage
                    src="../../assets/images/azszero-actions-01.png"
                    alt="Actions for Split Testing"
                    placeholder="tracedSVG"
                    layout="constrained"
                    //aspectRatio={1 / 1}
                    height="600"
                  ></StaticImage>
                </Col>
              </Row>
              <Row>
                <Col sm={12} className="pb-5">
                  <ul>
                    <li>
                      Test your strategies to see which one has better sales
                      results on your ASINs
                    </li>
                    <li>
                      The test will run and show live results comparing the
                      sales numbers before, during, and after the test was run.
                    </li>
                    <li>
                      Chose the strategy from various amazon built-in
                      advertisements, 3P deals, Social Media, and others.
                    </li>
                    <li>
                      Strategies also can be performed on split testing of
                      different pictures, keywords, or product pages.
                    </li>
                    <li>
                      Create unlimited Actions for all ASINs in any marketplace,
                      Strategies are saved for future reference on which worked
                      best.
                    </li>
                  </ul>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row>
            <Col>
              <Link to="/Amazon_FBA_Seller_App_Features">
                <BiArrowBack />
                &nbsp;&nbsp;Back to Features Page
              </Link>{" "}
            </Col>
          </Row>
        </Container>
      </Container>
    </Layout>
  )
}
const Styledh1 = styled.h1`
  padding: 30px;
  color: slategray;
  margin-bottom: -50px;
  z-index: 10;
  font-weight: 700;
`
export default Actions
